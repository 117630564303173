import React from 'react';
import './Navbar.css';

const Navbar = () => {
  return (
    <nav>
      <ul>
        <li>
          <a href="/">Home</a>
        </li>
        <li>
          <a href="/about">SMS</a>
        </li>
        <li>
          <a href="/footer">Data</a>
        </li>
        <li>
          {/* <a href="/adhar">Call Us</a> */}
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
